import '@aws-amplify/ui-react/styles.css';
import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import {
  fetchAuthSession,
  getCurrentUser,
  signInWithRedirect
} from 'aws-amplify/auth';

import awsconfig from './aws-exports.js';

import {
  Button,
  View,
  Card,

} from '@aws-amplify/ui-react';


awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;

awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

function App() {
  // Amazon Federate Midway athentication
  const [user, setUser] = useState<any|null>(null);
  // getUser function
  function getUser() {
    return getCurrentUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  // Use effect for auth
  useEffect(() => {
    Hub.listen('auth', ({payload}) => {
	switch (payload.event) {
        case "signedIn":
          console.log(payload.event);
		  console.log('user has been signedIn successfully');
          console.log(payload.data);
          getUser()
		  .then((userData) => setUser(userData));
          console.log(payload.data.username);
          break;
        case "signedOut":
          setUser(null);
          break;
        case "signInWithRedirect_failure":
          console.log("Sign in failure", payload.data);
          break;
      }
    });
    getUser().then((userData) => setUser(userData));
  }, []);
  return (
    <View className="App">
      <Card>
        <Button onClick={() => signInWithRedirect({provider:{custom:'FederateOIDC'}})}>Login</Button>
      </Card>
    </View>
  );
}

export default App;